import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sendtrx',
    name: 'SendTransaction',
    component: () => import(/* webpackChunkName: "sendtrx" */ '../views/SendTransaction.vue')
  },
  {
    path: '/chain',
    name: 'walletcreat',
    component: () => import(/* webpackChunkName: "walletcreat" */ '../views/WalletCreat.vue')
  },
   {
    path: '/sendtrx_test',
    name: 'TestSendTransaction',
    component: () => import(/* webpackChunkName: "sendtrx_test" */ '../views/TestSendTransaction.vue')
  },
  {
    path: '/chain_test',
    name: 'Testwalletcreat',
    component: () => import(/* webpackChunkName: "Testwalletcreat" */ '../views/Testwalletcreat.vue')
  },
  {
    path: '/sendtoken',
    name: 'TokenSendTransaction',
    component: () => import(/* webpackChunkName: "TokenSendTransaction" */ '../views/TokenSendTransaction.vue')
  },
  {
    path: '/buyAuto',
    name: 'buyAutoaction',
    component: () => import(/* webpackChunkName: "buyAutoaction" */ '../views/buyAutoaction.vue')
   }, 
   {
    path: '/sellAuto',
    name: 'sellAutoaction',
    component: () => import(/* webpackChunkName: "sellAutoaction" */ '../views/sellAutoaction.vue')
   },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
